import { z } from 'zod';
import type { ShowCustomerReviewPage } from '../types';
import $ from '../util/fancybox-jquery';
import { document, window } from '../globals';
import { withSiteUrl } from '../api/url';
import '../util/show-hide-body';

function assertProductPageReviewsResponse(data: unknown): asserts data is string {
    z.string().parse(data);
}

// productpage
const showCustomerReviewPage: ShowCustomerReviewPage = (pageId, articleId) => {
    const countProperty: unknown = $('.js-totalReviews').data('count');
    const reviewTotal = typeof countProperty === 'number' ? countProperty : 0;

    $.ajax({
        success: (data) => {
            assertProductPageReviewsResponse(data);
            $('.js-productPageReviews').html(data);
        },
        url: withSiteUrl(`product-page/reviews?pageId=${pageId}&productId=${articleId}&total=${reviewTotal}`),
    }).catch(() => null);
};

if (window) {
    window.showCustomerReviewPage = showCustomerReviewPage;
}

const updateFancy = (): void => {
    $('.fancybox').fancybox({
        padding: 25,
        swf: { wmode: 'transparent' },
    });

    $('.fancybox-iframe').fancybox({
        autoSize: false,
        height: 400,
        padding: 25,
        swf: { wmode: 'transparent' },
        type: 'iframe',
    });

    $('.fancybox-iframe-large').fancybox({
        autoSize: false,
        padding: 25,
        swf: { wmode: 'transparent' },
        type: 'iframe',
    });
};

const formState = (): void => {
    $('.form-input').not('.js-form-input-no-validation').on('keyup paste change', function onAction() {
        if ($(this).val() || $(this).attr('placeholder')) {
            $(this).addClass('dirty');
        } else {
            $(this).removeClass('dirty');
        }
    });

    $('.form-input').not('.js-form-input-no-validation').each(function onEach() {
        if ($(this).val() || $(this).attr('placeholder')) {
            $(this).addClass('dirty');
        } else {
            $(this).removeClass('dirty');
        }
    });
};

const togglePassword = (): void => {
    $('.jsTogglePassword').click((e) => {
        e.preventDefault();
        $('.jsShowPassword').attr('type', $('.jsShowPassword').attr('type') === 'text' ? 'password' : 'text');
    });
};

const handlePageDescription = (): void => {
    const CLOSED_HEIGHT = 42;
    const descriptionText = document?.getElementById('js-pageDescription');
    const descriptionReadMore = document?.getElementById('js-pageDescriptionMore');
    if (!descriptionText || !descriptionReadMore) {
        return;
    }
    if (descriptionText?.scrollHeight > CLOSED_HEIGHT) {
        descriptionReadMore?.classList.remove('hidden');
    }
    descriptionReadMore.addEventListener('click', () => {
        descriptionText.classList.remove('page-description--closed');
    });
};

const stats = (): void => {
    const statsref = window?.location.search;
    const ref = document?.referrer;
    $.ajax({
        cache: false,
        data: {
            get: statsref,
            ref,
        },
        type: 'GET',
        url: withSiteUrl('services/stats.php'),
    }).catch(() => null);
};

$((): void => {
    stats();

    document?.addEventListener('newFancy', (): void => {
        updateFancy();
    });

    updateFancy();
    formState();
    togglePassword();
    handlePageDescription();
});
